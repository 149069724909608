
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import {useI18n} from 'vue-i18n';
import {defineComponent} from 'vue';

export default defineComponent(
    {
      components: {Header, Footer},
      props: {
        sentinalName: {
          type: String,
          required: true,
        },
      },
      name: 'DashboardPage',
      setup() {
        const {t, locale} = useI18n();
        return {t, locale};
      },
      data() {
        return {
          s1_mastercard: false,
          s1_visa: false,
          s1_jcb: false,
          s2_firstchoice: false,
          s2_ktc: false,
          s2_krungsri: false,
          s3_dashboard: false,
          scrollPosition: 0,
          bottomScroll: false,
        };
      },
      methods: {
        s1AnimationToggle() {
          this.s1_mastercard = !this.s1_mastercard;
          this.s1_visa = !this.s1_visa;
          this.s1_jcb = !this.s1_jcb;
        },
        s2AnimationToggle() {
          this.s2_firstchoice = !this.s2_firstchoice;
          this.s2_ktc = !this.s2_ktc;
          this.s2_krungsri = !this.s2_krungsri;
        },
        updateScroll() {
          const s2 = this.$refs.s3 as any;
          if (s2 != null) {
            const result = s2.getBoundingClientRect().top - this.scrollPosition;
            this.scrollPosition = window.scrollY;
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
              // mobile
              if (result < -320) {
                if (!this.s3_dashboard) {
                  this.s3_dashboard = true;
                }
              }
              if (result > -280) {
                if (this.s3_dashboard) {
                  this.s3_dashboard = !this.s3_dashboard;
                }
              }
              if (result < -900) {
                this.s3_dashboard = !this.s3_dashboard;
              }
            }

            // desktop
            else {
              if (result < -1) {
                if (!this.s3_dashboard) {
                  this.s3_dashboard = true;
                }
              }
              if (result < -1370) {
                if (this.s3_dashboard) {
                  this.s3_dashboard = !this.s3_dashboard;
                }
              }
            }
          }
        },
      },
      mounted() {
        this.s1AnimationToggle();
        window.addEventListener('scroll', this.updateScroll);
      },
      destroy() {
        window.removeEventListener('scroll', this.updateScroll);
      },
    });
